// extracted by mini-css-extract-plugin
export var column__l3Vd = "PlasmicSubscribe-module--column__l3Vd--NDHIJ";
export var column__zwgA5 = "PlasmicSubscribe-module--column__zwgA5--NReEH";
export var columns = "PlasmicSubscribe-module--columns--1p6HT";
export var email = "PlasmicSubscribe-module--email--J9e1A";
export var firstName = "PlasmicSubscribe-module--firstName--qEcsM";
export var footer = "PlasmicSubscribe-module--footer--22bF7";
export var form = "PlasmicSubscribe-module--form--Kfj-l";
export var formButton = "PlasmicSubscribe-module--formButton--dog7s";
export var freeBox___85RYe = "PlasmicSubscribe-module--freeBox___85RYe--Alf4j";
export var freeBox__rvfQh = "PlasmicSubscribe-module--freeBox__rvfQh--W37wa";
export var freeBox__sjvpc = "PlasmicSubscribe-module--freeBox__sjvpc--FcUAn";
export var freeBox__vhoJl = "PlasmicSubscribe-module--freeBox__vhoJl--08gCa";
export var h1 = "PlasmicSubscribe-module--h1--f17qT";
export var lastName = "PlasmicSubscribe-module--lastName--biFQo";
export var navBar = "PlasmicSubscribe-module--navBar--KvLUZ";
export var root = "PlasmicSubscribe-module--root--e10Wj";
export var svg__atlE5 = "PlasmicSubscribe-module--svg__atlE5--hhU00";
export var svg__g8D2P = "PlasmicSubscribe-module--svg__g8D2P--GeNNt";
export var svg__gHdyL = "PlasmicSubscribe-module--svg__gHdyL--Me6Pc";
export var svg__jkLo = "PlasmicSubscribe-module--svg__jkLo--8p7b7";
export var svg__m1Pcp = "PlasmicSubscribe-module--svg__m1Pcp--epREY";
export var svg__mv0U1 = "PlasmicSubscribe-module--svg__mv0U1--UbIE4";
export var text = "PlasmicSubscribe-module--text--H4yaw";