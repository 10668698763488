// This is a skeleton starter React page generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react"
import {useState, useEffect} from "react"
import { PlasmicSubscribe } from "../components/plasmic/stack_input_site/PlasmicSubscribe"

const Airtable = require('airtable');


function Subscribe() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState(false)


  Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: "key5t3q3H8TgvBOMs"
  });
  var base = Airtable.base('appldDJDdY8eUHXyx');

  const url = 'https://api.airtable.com/v0/appldDJDdY8eUHXyx/tbllxQ7KFFhtwQ29R'

  async function sendDataToAirTable() {
    await base('ContactForm').create([
      {
        "fields": {
          'FirstName': firstName,
          'LastName': lastName,
          'Email': email,
          'Time': new Date(Date.now()).toDateString()
        }
      },

    ], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      setFirstName('')
      setLastName('')
      setEmail('')
      setSubscribed(true)
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });

  }


  // Use PlasmicSubscribe to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSubscribe are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicSubscribe is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Gatsby "wrapRootElement" function
  // (https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr#wrapRootElement).
  return <PlasmicSubscribe
    firstName={{
      autoFocus: false,
      value: firstName,

      onChange: (e) => {
        setFirstName(e.target.value);
      },
    }}

    lastName={{
      autoFocus: false,
      value: lastName,

      onChange: (e) => {
        setLastName(e.target.value);
      },
    }}


    email={{
      autoFocus: false,
      value: email,

      onChange: (e) => {
        setEmail(e.target.value);
      },
    }}


    formButton={{
      onClick: () => {
        sendDataToAirTable()
      },
      subscribed: subscribed
    }
    } />;
}


export default Subscribe
